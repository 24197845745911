import { createActionThunk } from 'redux-thunk-actions';

import AT from '../actionTypes';

import * as API from '../../services/api.services';

export const setUserState = (value) => (
    (dispatch) => dispatch({
        type: AT.ADD_STATE_USER,
        payload: {
            value
        }
    })
)

export const sendMessage = createActionThunk(
    AT.SEND_MESSAGE,
    (params) => API.sendMessage(params)
);


export const scheduleMessage = createActionThunk(
    AT.SEND_MESSAGE,
    (params) => API.sendScheduleMessage(params)
);


export const sendSMSInstant = createActionThunk(
    AT.SEND_MESSAGE,
    (params) => API.sendInstantMessage(params)
);




export const saveHashtagAPI = createActionThunk(
    AT.SAVE_HASH_TAG,
    (params) => API.saveHashtagAPI(params)
);