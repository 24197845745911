import { createStore, applyMiddleware, compose } from 'redux';
import { sessionService } from 'redux-react-session';
import thunk from 'redux-thunk';
import RootReducer from './reducers';

const composeEnhancers = typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const store = createStore(RootReducer, composeEnhancers(applyMiddleware(thunk)))
sessionService.initSessionService(store, { driver: 'LOCALSTORAGE' });

export default store;
