import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './MessageForm.css';
import * as Constants from '../../utils/constants';
import * as API from '../../services/api.services';
import HeadlineWithSubtitle from '../HeadlineWithSubtitle/HeadlineWithSubtitle';
import { Button, DatePicker, Divider, Form, Radio, Select, TimePicker, Input, Space,Modal, message, List } from 'antd';
import MessagePreview from '../MessagePreview';


function MessageForm(){
  
  const { Option } = Select;
  const { TextArea } = Input;
  const [FrequencyValue, setFrequencyValue] = useState(1);
  const [EndingValue, setEndingValue] = useState(1);
  const [openMessageLibrary, setOpenMessageLibrary] = useState(false);
  const [openMessagePreview, setOpenMessagePreview] = useState(false);
  const [hideCampaignCalendar,setHideCampaignCalendar]= useState("hidden");
  const toggleCampaignCalendar=()=>{
    let campaignDiv = document.getElementById("campaignrangediv");
    let hidden = campaignDiv.getAttribute("hidden");
    if (hidden) {
      campaignDiv.removeAttribute("hidden");
   } else {
    campaignDiv.setAttribute("hidden", "hidden");
   }
  }
  

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'This is a success message',
    });
  };
  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'This is an error message',
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'This is a warning message',
    });
  };
  const frequencyWarning =(type) =>{
      messageApi.open({
        type: 'error',
        content: type + ' frequency can only be sent with a paid plan.',
      });
  
  }
  const onFrequencyValueSelected = (e) => {
      let selectedFrequency = e.target.value 
      switch (selectedFrequency){
        case "Once": 
        console.log('radio checked', e.target.value);
        setFrequencyValue(e.target.value);
       // toggleCampaignCalendar()
        break
        case "Daily":
          //TODO : do a check to see if users credential will allow them to send with other frequencies.
          frequencyWarning(selectedFrequency)
          toggleCampaignCalendar()
          break
        case "Weekly":
          //TODO : do a check to see if users credential will allow them to send with other frequencies.
          frequencyWarning(selectedFrequency)
          break
        case "Monthly":
          //TODO : do a check to see if users credential will allow them to send with other frequencies.
          frequencyWarning(selectedFrequency)
          break
      }
      
    };
    const onEndingValueSelected = (e) => {
      console.log('radio checked', e.target.value);
      setEndingValue(e.target.value);
    };
  const [CustomerMessage,setCustomerMessage]=useState();
  const onMessageChanged =(e)=>{
    console.log('Message here', e.target.value);
    setCustomerMessage(e.target.value);
  }
  const [MessageList,setMessageList] = useState([]);
    const hydrateMessageList=()=>{
      setMessageList([...MessageList,...API.getAllMessages()])
  }
  const [CustomerFilter,setCustomerFilter] = useState("All Customers");
  const [CustomerList,setCustomerList] = useState([]);
 
  useEffect(() => {
    setCustomerList(API.getFilteredListOfCustomers(CustomerFilter))
  }, []);
 
  const openMessageLibraryModal=()=>{
      hydrateMessageList()
      setOpenMessageLibrary(true)
    }
  const openMessagePreviewModal=()=>{
      setOpenMessagePreview(true)
    }
  const selectCustomerMessage=(SelectedMessage)=>{
    console.log(SelectedMessage.item)
    setCustomerMessage(SelectedMessage.item)
    setOpenMessageLibrary(false)
  }

  return(
  <div className="MessageForm">
    {contextHolder}
    <Form>
      <HeadlineWithSubtitle Headline={Constants.MF_Headline} Subtitle={Constants.MF_Subtitle} />
    <Divider/>
   
    <label className='labelheader'> 
    {Constants.MF_SegmentLabel}  
     </label>
   
    <div>
    <Form.Item
        name="select-multiple"
        rules={[
          {
            required: true,
            type: 'array',
          },
        ]}
      >
 <Select mode="single" placeholder={Constants.MF_SegmentLabel}>
        {
          Constants.MF_SegmentSelect.map((value,index)=>{
            return <Option value={value} key={index}>{value}</Option>
          })
        }
</Select>
<label className='labelheader'> 
   Customers : {CustomerList.length}  
</label>
      </Form.Item>
      </div>
      <label className='labelheader'>
     {Constants.MF_MessageLabel}
      </label>
     
     
      <Button className='librarybutton' onClick={openMessageLibraryModal}>
     {Constants.MF_LibraryButtonLabel} 
      </Button>

      <Modal
        title={Constants.MF_LibraryButtonLabel} 
        centered
        open={openMessageLibrary}
        onOk={() => setOpenMessageLibrary(false)}
        onCancel={() => setOpenMessageLibrary(false)}
        width={1000}
      >
        
        <List
      bordered
      dataSource={MessageList}
      renderItem={(item,val) => (
        <List.Item >
          {item} 
          <div>
          <Button  onClick={()=>selectCustomerMessage({item})} >
            Select Message
          </Button>
          </div>
          
        </List.Item>
      )}
    />
      </Modal>
    
      
   <TextArea showCount maxLength={300} style={{
        height: 150,
        resize: 'none',
      }}
      
      placeholder={Constants.MF_MessageSample} onChange={onMessageChanged} value={CustomerMessage} >
   </TextArea>

   <label className='labelheader'>
   {Constants.MF_FrequencyLabel}
   </label>
   <Radio.Group onChange={onFrequencyValueSelected} value={FrequencyValue}>

   {
      Constants.MF_Frequency.map((item,index) => {
        return (<Radio value={item} key={index}>{item}</Radio>)
      }

      )
    }
     
    </Radio.Group>
    <label className='labelheader'>
      {Constants.MF_DateTimeLabel}
    </label>
    
    <div className='datetimepickerdiv' >
        <div className='item'>
        <DatePicker />
        </div>
        <div className='item'>
        <TimePicker/>
        </div>
    </div>
<div id='campaignrangediv' hidden>
  <label className='labelheader'>
        {Constants.MF_EndDateTimeLabel}
  </label>
   <Radio.Group onChange={onEndingValueSelected} value={EndingValue}>
        {
            Constants.MF_EndingValues.map((value,index)=>{
            return( <Radio value={value} key={index}>{value}</Radio>)
          })
        }
  </Radio.Group>
</div>

    <Modal
        title={Constants.PREVIEW_HEADER_TITLE}
        centered
        open={openMessagePreview}
        onOk={() => setOpenMessagePreview(false)}
        onCancel={() => setOpenMessagePreview(false)}
        width={500}
      >
        <div>
       <div style={{padding:20}}> 
        {Constants.PREVIEW_HEADER_SUBTITLE}
       <Space direction="vertical" size="large" />
       </div>
       <MessagePreview message={CustomerMessage}/>
       </div>
      </Modal>
      <Button className='librarybutton' onClick={openMessagePreviewModal}>
     Preview Message
      </Button>
    <Button/>
    </Form>
  </div>
  )
}

MessageForm.propTypes = {};

MessageForm.defaultProps = {};

export default MessageForm;
