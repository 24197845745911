import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import { Hashtag, Into, Login, Code, Done, Send,SendMessage, HowWork, Success, Sub, SignUp, ComingSoon,NewMessage } from "../pages";
import Header from "../components/Header";
import HeaderGuest from '../components/HeaderGuest';
import firebase from "../services/firebase";
import ScheduleMessage from "../pages/scheduleMessage/scheduleMessage";


const AppRouters = () => {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState()

  function onAuthStateChanged(user) {
    setUser(user);
    if (initializing) setInitializing(false);
  }

  useEffect(() => {
    const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber;
  }, []);
  const reload = () => window.location.reload();
  const loadStaticLandingPage = () => window.location.replace("/home.html");
  //<Route path="/" exact component={ComingSoon} />

  return (
    <BrowserRouter>
      <StickyContainer>
        {user && <Header />}
        {!user && HeaderGuest}
        <Switch>
        <Route path="" exact render={loadStaticLandingPage} />
          <Route path="/" exact render={loadStaticLandingPage} />
          <Route path="/wf.html" render={reload} />
          <Route path="/newmessage" exact component={NewMessage}/>
          <Route path="/login" exact component={Login} />
          <Route path="/signup" exact component={SignUp} />
          <Route path="/code" exact component={Code} />
          <Route path="/howWork" exact component={HowWork} />
          {
            user &&
            <Route path="/hashtag" exact component={Hashtag} />
          }
          {
            user &&
            <Route path="/done" exact component={Done} />
          }
          {
            user &&
            <Route path="/send" exact component={SendMessage} />
          }
          {
            user &&
            <Route path="/success" exact component={Success} />
          }
          {
            user &&
            <Route path="/subscriber" exact component={Sub} />
          }
          {
            user &&
            <Route path="/schedule-message" component={ScheduleMessage} />
          }

        </Switch>
      </StickyContainer>
    </BrowserRouter>
  );
};

export default AppRouters;
