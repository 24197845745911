import { createReducer } from 'redux-create-reducer';

import AT from '../actionTypes';

const initialState = {
  // todo
  userState: {},
};

const ADD_STATE_USER = `${AT.ADD_STATE_USER}`;
const SEND_MESSAGE = `${AT.SEND_MESSAGE}`;

export default createReducer(initialState, {
  [ADD_STATE_USER](state, action) {
    const userState = action.payload.value || action.payload;
    return Object.assign({}, state, {
      userState
    });
  },
  [SEND_MESSAGE](state, action) {
    return Object.assign({}, state, {
      loading: false,
    });
  }
});
