import Axios from 'axios';
import { BASE_API, FIRE_BASE_API } from '../constants';
import * as Constants from '.././utils/constants';
import { refresh } from 'less';

const getAxiosForRedux = (url, payload = {}, params = {}) => {
    return Axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
        params,
    })
        .then((result) => {
            if (!!Object.keys(payload).length) {
                return { data: result.data, ...payload };
            }
            return result.data;
        })
        .catch((error) => {
            // alert(error);
            if (payload.pending) {
                console.log('pending data error');
            }
        });
};

const deleteAxios = (url) => {
    return Axios.delete(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    }).catch((error) => {
        // alert(error);
    });
};

const postAxiosForRedux = (url, params, headers = {}, payload = {}) => {
    return Axios.post(url, params, {
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
    })
        .then((result) => {
            if (payload.data) {
                return { data: result.data, ...payload.data };
            }
            return result.data;
        })
        .catch((error) => {
            console.log('error >>>', error);
        });
};

const putAxiosForRedux = (url, params, payload = {}) => {
    return Axios.put(url, params, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((result) => {
            if (payload.data) {
                return { data: result.data, ...payload.data };
            }
            return result.data;
        })
        .catch((error) => { });
};

const getAxios = (url) => {
    return Axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    }).catch((error) => { });
};

const putAxios = (url, params) => {
    return Axios.put(url, params, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((result) => {
            return result.data;
        })
        .catch((error) => { });
};

const patchAxios = (url, params) => {
    return Axios.patch(url, params, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((result) => {
            return result.data;
        })
        .catch((error) => { });
};

const postAxios = (url, params, headers = {}) => {
    return Axios.post(url, params, {
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
    })
        .then((result) => {
            return result.data;
        })
        .catch((error) => { });
};

export const sendMessage = (params) => {
    const url = `https://us-central1-tapmein-3b357.cloudfunctions.net/sendSMS`;
    return postAxiosForRedux(url, params);
};



export const sendScheduleMessage = (params) => {
    const url = `https://us-central1-tapmein-3b357.cloudfunctions.net/sendSMSTimely`;
    return postAxiosForRedux(url, params);
};


export const sendInstantMessage = (params) => {
    const url = `https://us-central1-tapmein-3b357.cloudfunctions.net/sendSMSInstant`;
    return postAxiosForRedux(url, params);
};



export const saveHashtagAPI = (params) => {
    const url = `${FIRE_BASE_API}/HashTags.json`;
    return postAxiosForRedux(url, params);
};
export const getAllMessages=()=>{
    let ListOfMessages = [];
    Object.keys(Constants.MF_Mock_Messages).forEach((key)=>{
      Object.keys(Constants.MF_Mock_Messages[key]).forEach((messageItem)=>{
        const Message = Constants.MF_Mock_Messages[key][messageItem]
        ListOfMessages.push(Message)
      })
    })
    return ListOfMessages
}
let ListOfCustomers =[]
const CustomerList = Constants.ALL_CUSTOMERS
const getAllCustomers=()=>{
         //TODO : replace with the retrieval from Firebase
         Object.keys(CustomerList).forEach((CustomerObject)=>{
             Object.keys(CustomerList[CustomerObject]).forEach((CustomerEntry)=>{
               const Customer = CustomerList[CustomerObject][CustomerEntry]
               ListOfCustomers.push(Customer)  
             })
     
           // console.log(CustomerList[CustomerObject][0]["id"])
           // console.log(CustomerList[CustomerObject][0]["given_name"])
           // console.log(CustomerList[CustomerObject][0]["family_name"])
           // console.log(CustomerList[CustomerObject][0].family_name)
           // console.log(CustomerList[CustomerObject][0].address)
         })
}
export const getFilteredListOfCustomers=(filters)=>{
        //case logic(Usually ran after initial load of customers)
        switch(filters){
            case "All Customers":
                getAllCustomers()
                return ListOfCustomers
            case "New Customers":
                //do logic to slice out the new customers
        }
   // console.log(ListOfCustomers)
    

}


