import React, { useEffect, useState } from 'react'
import { Typography, Button, Form, Input } from 'antd';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import firebase from "../../services/firebase";
import { saveHashtagAPI } from '../../store/actions';
import * as Constants from '../../utils/constants';
import "../hashtag/Hashtag.css";

const { Title } = Typography;

const Index = () => {
    const history = new useHistory();
    const [hashtag, setHashtag] = useState();
    const [loading, setLoading] = useState(false);
    const [breaked, setBreaked] = useState(false);
    const user = useSelector((state) => state.common.userState);
    const dispatch = useDispatch();

    const actionCreate = async (key1, key2) => {
        const hashTagValue = {
            value: hashtag,
            timeCreate: firebase.database.ServerValue.TIMESTAMP,
        }
        if (key1 && key2) {
            firebase.database().ref('users_hashtags').child(user.displayName).child(key2).set(true).then(
                () => {
                    setHashtag();
                    history.push({
                        pathname: '/done',
                        state: { hashtag: hashtag, timeCreate: new Date() }
                    });
                    setLoading(false);
                }
            ).catch(() => {
                setLoading(false);
            })
        } else {
            //Create the hashtag
            firebase.database().ref('HashTags').push(hashTagValue)
                .then((value) => {
                    if (value) {
                        // value.key is key create when push hashTagValue
                        // user.displayName is key user
                        firebase.database().ref('users_hashtags').child(user.displayName).child(value.key).set(true).then(
                            () => {
                                setHashtag();
                                history.push({
                                    pathname: '/done',
                                    state: { hashtag: hashtag, timeCreate: new Date() }
                                });
                                setLoading(false);
                            }
                        ).catch(() => {
                            setLoading(false);
                        })
                    }
                })
                .catch((e) => {
                    alert(e)
                    setLoading(false);
                });
        }
        setLoading(false);
    }

    const saveHashtag = () => {
        if (hashtag && hashtag.length > 0 && user && user.phoneNumber && user.displayName) {
            setLoading(true);
            const hashTagValue = {
                value: hashtag,
                timeCreate: firebase.database.ServerValue.TIMESTAMP,
            }
            let nameHash = firebase.database().ref('HashTags')
            let Check = true;
            let keyChjec = "";
            nameHash.once('value', function (vale, key) {
                let value = vale.val();
                if (value) {
                    Object.keys(value).map(keyHash => {
                        keyChjec = keyHash;
                        if (value[keyHash]["value"] == hashtag) {
                            Check = false;
                        }
                    })
                    if (Check) {
                        actionCreate(user.displayName, null);
                    } else {
                        actionCreate(user.displayName, keyChjec);
                    }

                } else {
                    actionCreate(user.displayName, null);
                }
            })
        } else {
            alert("Enter your hashtag")
        }
    }

    /*const test = () => {
        firebase.database().ref('users_subs_hashtags').child("-MS3e9PGwFaVgT9F5pDb").child("-MS3eEQm_mQzdRwBsEzt").set(true)
    }*/

    return (
        <div className="container-into">
            <div className='Title'>{Constants.Hashtag_Title}</div>
           <div className='Subtitle'>{Constants.Hashtag_Subtitle}</div>
           
            <Form.Item colon={false}>
                <Input value={hashtag} placeholder={Constants.Hashtag_Input_placeholder} onChange={(e) => { setHashtag(e.target.value) }} style={{ width: '100%', marginTop: 20, marginBottom: 20, borderBottom: "1px solid rgb(212, 212, 212)" }} bordered={false} />
               
            </Form.Item>
            <div className="group-button-into">
                <Button loading={loading} onClick={saveHashtag} className="button-into" type="primary">Next</Button>
            </div>
        </div>
    )
}

export default Index;