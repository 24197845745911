
export const ADD_STATE_USER = 'ADD_STATE_USER';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SAVE_HASH_TAG = 'SAVE_HASH_TAG';

const Types = {
    ADD_STATE_USER,
    SEND_MESSAGE,
    SAVE_HASH_TAG
}

export default Types;
