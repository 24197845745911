import React, { useEffect, useState } from 'react'
import { Typography, Button, Form, Input, Modal, List, Skeleton, Radio, Checkbox } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from "react-router-dom";
import firebase from "../../services/firebase";
import "./send.scss";
import { useSelector } from 'react-redux';
import coverTime from "../../utils/CoverTime";
import Select from "react-select";
import { useDispatch } from 'react-redux';
import { sendMessage } from '../../store/actions';
import * as Constants from '../../utils/constants';

const { Title } = Typography;
const { TextArea } = Input;

const Index = () => {
    const [message, setMessage] = useState();
    const [isShow, setIsShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [radioValue, setRadioValue] = useState([]);
    const [data, setData] = useState([]);
    const [dataSub, setDataSub] = useState([]);
    const user = useSelector((state) => state.common.userState);
    const history = new useHistory();
    const dispatch = useDispatch();

    const setModal = () => {
        setIsShow(!isShow);
    }

    const setSubApi = async (userCheck) => {
        setLoading(true);
        //  get all sub
        const snapsub = await firebase.database().ref('sub')
        snapsub.on('value', (valueSub, key) => {
            let sub = valueSub.val();
            // get all users_subs_hashtags
            const users_subs = firebase.database().ref('users_subs_hashtags')
            users_subs.on('value', async (valueAll, key) => {
                let valueCheckAll = valueAll.val();
                if (valueCheckAll) {
                    let arraySubAll = [];
                    if (userCheck && userCheck.phoneNumber) {
                        // get all users_hashtags
                        let dbRef = firebase.database().ref('users_hashtags')
                        dbRef.on('value', (val, key) => {
                            let value = val.val();
                            if (value && value[user.displayName]) {
                                Object.keys(value[user.displayName]).map(keyUserHash => {
                                    if (value[user.displayName][keyUserHash] == true && valueCheckAll[keyUserHash]) {
                                        Object.keys(valueCheckAll[keyUserHash]).map(keyUserSubHash => {
                                            if (sub[keyUserSubHash]) {
                                                arraySubAll = [...arraySubAll, sub[keyUserSubHash]]
                                                setDataSub(arraySubAll);
                                                setLoading(false)
                                            }
                                        })
                                    }
                                })
                            }
                        }, function (error) {
                            alert(error);
                            setLoading(false)

                        });
                    }
                }
            })
        })
    }

    const onSub = () => {
        history.push('./subscriber');
    }

    const setDataApi = async (userCheck) => {
        setLoading(true);
        let HashTagsCheck = {};
        let nameHash = await firebase.database().ref('HashTags')
        nameHash.on('value', function (vale, key) {
            let value = vale.val();
            HashTagsCheck = value;
        })
        let array = [];
        if (userCheck && userCheck.phoneNumber) {
            let dbRef = firebase.database().ref('users_hashtags')
            dbRef.on('value', function (val, key) {
                let value = val.val();
                // user.displayName is key user
                // HashTagsCheck is all hashTAg table
                // keyCheck is key in users_hashtags
                if (value && value[user.displayName]) {
                    Object.keys(value[user.displayName]).map(keyCheck => {
                        if (value[user.displayName][keyCheck] == true && HashTagsCheck[keyCheck]) {
                            array = [...array, HashTagsCheck[keyCheck]]
                            setData(array)
                        }
                    })
                }
            }, function (error) {
                alert(error)
            });
        }
        setLoading(false)
    }


    useEffect(() => {
        if (user && Object.keys(user).length > 0) {
            setDataApi(user)
            setSubApi(user)
        }

        console.log({ user })
    }, [user])

    const onChangeRadio = (value, index) => {
        if (data && data[index]) {
            if (!radioValue.find(r => r.id == index)) {
                setRadioValue([{
                    value: data[index]["value"],
                    id: index,
                    timeCreate: data[index]["timeCreate"],
                    checked: value.target.checked,
                }])
            } else {
                setRadioValue([])
            }
        }
    }

    const onSend = async () => {
        setLoading(true);
        if (message && message.length > 0 && radioValue && radioValue.length > 0) {
            await dispatch(sendMessage({
                passcode: "tmi12345",
                hashtag: radioValue[0]["value"],
                message: message,
            }));
            setLoading(false);
            history.push({
                pathname: '/success',
                state: { message: message, radioValue: radioValue, timeCreate: new Date() }
            });
        } else {
            alert("Please Enter Your Message")
        }
    }

    return (
        <div className="container-into">
            <Title level={2} style={{ color: '#007aff', textAlign: 'left', marginTop: 20 }}>{Constants.Send_Title}</Title>
            <Title level={4} style={{ color: '#424242', textAlign: 'left' }}>{Constants.Send_Subtitle}</Title>

            <Form.Item colon={false}>
                <div style={{ marginBottom: 10, borderBottom: "1px solid rgb(212, 212, 212)" }}>
                    <Button onClick={setModal} className="button-into" style={{ textAlign: 'left', fontWeight: 500 }} type="link">{Constants.Send_Hashtag_placeholder}</Button>
                </div>
                <div onClick={onSub}>
                    <p style={{ textAlign: 'right', textDecoration: "underline", fontWeight: 500 }}>{dataSub && dataSub.length > 0 ? `${dataSub.length} Subscribers` : "Subscribers"}</p>
                </div>
                <TextArea onChange={(value) => setMessage(value.target.value)} value={message} rows={6} placeholder={Constants.Send_Message_placeholder} style={{ width: '100%', borderBottom: "1px solid rgb(212, 212, 212)", }} bordered={false} />
                <p style={{ textAlign: "right" }}>{message ? message.length : 0}/160</p>
            </Form.Item>
            <div className="group-button-into">

                {
                    radioValue && radioValue[0] ? <Title level={4} style={{ color: '#424242' }}> Hashtag : {radioValue[0]["value"]}</Title> : null
                }
                <Button loading={loading} onClick={onSend} className="button-into" type="primary">{Constants.Send_SendButton}</Button>
            </div>
            {console.log({ data })
            }
            {/* hash tag modal */}
            <Modal
                title={Constants.Send_SelectHashtag}
                visible={isShow}
                onOk={setModal}
                onCancel={setModal}
            >
                <div className="infinite-container">
                    <InfiniteScroll>
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={(item, index) => (
                                <List.Item
                                    actions={[<Checkbox checked={radioValue && radioValue[0] && radioValue[0]["id"] == index ? radioValue[0]["checked"] : false} onChange={(value) => { onChangeRadio(value, index) }}></Checkbox>]}
                                >
                                    <Skeleton avatar title={false} loading={item.loading} active>
                                        <List.Item.Meta
                                            title={<a href="https://ant.design">{item.value}</a>}
                                            description={item.timeCreate ? coverTime(item.timeCreate) : ""}
                                        />
                                    </Skeleton>
                                </List.Item>
                            )}
                        />
                    </InfiniteScroll>
                </div>
            </Modal>
        </div>
    )
}

export default Index;