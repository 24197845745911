import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Form,
  Input,
  Modal,
  List,
  Skeleton,
  Radio,
  Checkbox,
  Select,
} from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
import firebase from "../../services/firebase";
import app2 from "../../services/firebase";
import "./send.scss";
import { useSelector } from "react-redux";
import coverTime from "../../utils/CoverTime";
import { useDispatch } from "react-redux";
import { sendMessage } from "../../store/actions";
import axios from "axios";
import * as Constants from '../../utils/constants';

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const Index = () => {
  const [message, setMessage] = useState();
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState([]);
  const [data, setData] = useState([]);
  const [hashTagData, setHashTagData] = useState([]);
  const [dataSub, setDataSub] = useState([]);
  const [subCount, setCurrentHashtagSubscriberCount] = useState(0);
  const [currentHashSub, setCurrentHashtagSubscriberList] = useState([]);
  const [currentTag, setCurrentHashtagName] = useState("");
  const user = useSelector((state) => state.common.userState);
  const history = new useHistory();
  const dispatch = useDispatch();
  const [userLocal, setUserLocal] = useState({});
  const setModal = () => {
    setIsShow(!isShow);
  };

  const setSubApi = async (user) => {
    setLoading(true);
    //  get all subscriber to later on see which subscriber has the join key with the hashtag that the user owns

    const subscribers = await firebase.database().ref("sub");
    subscribers.on("value", (subscriber_item, key) => {
      let subscriber = subscriber_item.val();

      /*
      * Starting to get the hashtags that the user owns
      */
      const subscribers_hashtags = firebase.database().ref("users_subs_hashtags");
      subscribers_hashtags.on("value", async (subscriber_hashtag_item, key) => {
        let subscriber_hashtag = subscriber_hashtag_item.val();
        if (subscriber_hashtag) {
          let subscriber_arr = [];
          if (user && user.phoneNumber) {
            // get all users_hashtags
            let users_hashtags = firebase.database().ref("users_hashtags");
            users_hashtags.on(
              "value",
              (user_hashtag_item, key) => {
                let user_hashtag = user_hashtag_item.val();
                if (user_hashtag && user_hashtag[user.displayName]) {
                  Object.keys(user_hashtag[user.displayName]).map((user_hashtag_key) => {
                    //if the current hashtag in the user_hashtag is owned by the user and the subscriber also subscribe to this hashtag
                    if (
                      user_hashtag[user.displayName][user_hashtag_key] == true &&
                      subscriber_hashtag[user_hashtag_key]
                    ) {
                      //add the subscriber into the array
                      Object.keys(subscriber_hashtag[user_hashtag_key]).map(
                        (subscriber_hashtag_key) => {
                          if (subscriber[subscriber_hashtag_key]) {
                            //keyUserHash is the hash key in db
                            subscriber_arr = [...subscriber_arr, subscriber[subscriber_hashtag_key]];
                            setDataSub(subscriber_arr);
                            setLoading(false);
                          }
                        }
                      );
                    }
                  });
                }
              },
              function (error) {
                alert(error);
                setLoading(false);
              }
            );
          }
        }
      });
    });
  };

  const setDataApi = async (userCheck) => {
    setLoading(true);
    let HashTagsCheck = {};
    let nameHash = await firebase.database().ref("HashTags");
    nameHash.on("value", function (vale, key) {
      let value = vale.val();
      HashTagsCheck = value;
    });
    let array = [];
    if (userCheck && userCheck.phoneNumber) {
      let dbRef = firebase.database().ref("users_hashtags");
      dbRef.on(
        "value",
        function (val, key) {
          let value = val.val();
          // user.displayName is key user
          // HashTagsCheck is all hashTAg table
          // keyCheck is key in users_hashtags
          if (value && value[user.displayName]) {
            Object.keys(value[user.displayName]).map((keyCheck) => {
              if (
                value[user.displayName][keyCheck] == true &&
                HashTagsCheck[keyCheck]
              ) {
                axios
                  .get(
                    "https://tmi-subscribers.firebaseio.com/" +
                      HashTagsCheck[keyCheck]["value"] +
                      ".json"
                  )
                  .then(function (response) {
                    let data = response.data;
                    let sub_count = 0;
                    let sub_arr = [];
                    if (data) {
                      Object.keys(data).map(function (key) {
                        sub_count = sub_count + 1;
                        sub_arr.push(data[key]);
                      });
                    }
                    let tempHash = {
                      value: HashTagsCheck[keyCheck]["value"],
                      sub_count: sub_count,
                      timeCreate: HashTagsCheck[keyCheck]["timeCreate"],
                      subs: sub_arr,
                    };
                    array = [...array, tempHash];
                    setData(array);
                  });
              }
            });
          }
          console.log(data);
        },
        function (error) {
          alert(error);
        }
      );
    }

    setLoading(false);
  };
      
  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      setDataApi(user);
      firebase
        .database()
        .ref("user")
        .child(user.displayName)
        .on("value", async (valueAll, key) => {
          if (valueAll.val()) {
            setUserLocal(valueAll.val());
          }
        });
    }
  }, [user]);

  const onChangeRadio = (value, index) => {
    if (data && data[index]) {
      if (!radioValue.find((r) => r.id == index)) {
        setRadioValue([
          {
            value: data[index]["value"],
            id: index,
            timeCreate: data[index]["timeCreate"],
            checked: value.target.checked,
          },
        ]);
      } else {
        setRadioValue([]);
      }
    }
  };

  const onSendMessage = async () => {
    if (userLocal?.message_remained <= 0) {
      alert("Sorry, you are out of messages for this month. Please contact support@tribetext.co for additional messages");
    } else {
      setLoading(true);
      if (
        message &&
        message.length > 0 &&
        currentTag &&
        currentTag.length > 0
      ) {
        const sendSucess = await dispatch(
          sendMessage({
            passcode: "tmi12345",
            hashtag: currentTag,
            message: message,
          })
        );
        if (sendSucess) {
          firebase
            .database()
            .ref("user")
            .child(user.displayName) //TODO: get user ref
            .update({
              message_remained: userLocal.message_remained - 1 || 0,
            });
        }
        setLoading(false);
        history.push({
          pathname: "/success",
          state: {
            message: message,
            currentTag: currentTag,
            timeCreate: new Date(),
          },
        });
      } else {
        alert("Please Enter Your Message");
      }
    }
  };


//  




// Iterates through all hashtags, finds currently selected hashtag info, and binds it to the values in the class that we will need
  const handleSub = (SelectedHashtagName) => {
    data.map((HashtagObject) => {
      if (HashtagObject.value == SelectedHashtagName) {
        setCurrentHashtagSubscriberCount(HashtagObject.sub_count);
        setCurrentHashtagSubscriberList(HashtagObject.subs);
        setCurrentHashtagName(SelectedHashtagName);
      }
    });
  };

  const handleLoadMore = () => {

  };

  return (
    <div className="container-into">
      <div className="Title">
        {Constants.Send_Title}
      </div>

      <div className="Subtitle">
       {Constants.Send_Subtitle}
      </div>

      <Form.Item colon={false}>
        <Select
          showSearch
          placeholder={Constants.Send_Hashtag_placeholder}
          onChange={handleSub}
          style={{ textAlign: "left" }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {(data || []).map((item, index) => (
            <Option key={index} value={item.value}>
              {item.value}
            </Option>
          ))}
        </Select>
        <div onClick={setModal}>
          <p
            style={{
              textAlign: "right",
              textDecoration: "underline",
              fontWeight: 500,
            }}
          >
            {subCount && subCount > 0 ? `${subCount} Subscribers` : ""}
          </p>
        </div>
        <TextArea
         
          onChange={(value) => setMessage(value.target.value)}
          value={message}
          rows={4}
          placeholder={Constants.Send_Message_placeholder}
          maxLength="160"
          style={{
            width: "100%",
            borderBottom: "1px solid rgb(212, 212, 212)",
          }}
          bordered={false}
        />
        <p style={{ textAlign: "right" }}>{message ? message.length : 0}/160</p>
      </Form.Item>
      <div className="group-button-into">
        <Button
          loading={loading}
          onClick={onSendMessage}
          className="button-into"
          type="primary"
        >
          {Constants.Send_SendButton}
        </Button>
      </div>
      {/* hash tag modal */}
      <Modal
        title="Preview subscribers"
        visible={isShow}
        onOk={setModal}
        onCancel={setModal}
        footer={[
          <Button type="primary" onClick={setModal}>
            Ok
          </Button>,
        ]}
      >
        <div className="infinite-container">
          <InfiniteScroll loadMore={handleLoadMore}>
            <List
              itemLayout="horizontal"
              dataSource={currentHashSub}
              renderItem={(item, index) => (
                <List.Item key={index}>
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      title={
                        <a href="#">{`XXX-XXX-${item.phonenumber.substring(
                          item.phonenumber.length - 4
                        )}`}</a>
                      }
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      </Modal>
    </div>
  );
};

export default Index;