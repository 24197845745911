import React from 'react'
import { Typography, Button, Space } from 'antd';
import { useHistory } from "react-router-dom";
import * as Constants from '../../utils/constants';
const HIW = Constants.HowItWorksItems;

const Index = () => {
    const history = new useHistory();

    const onOk = () => {
        history.push("/signup");
    }
    const onHome = () => {
        history.push("/");
    }

    return (
    <div className="container-into">
    <div className='Title'>How {Constants.BrandName} Works.</div>
        <Space></Space>
      {
            HIW.map((HowItWorksItem)=> {
             return <div>
             <div className='TitleBold'> {HowItWorksItem.steptitle} </div>
             <div className='NormalText'> {HowItWorksItem.subtitle} </div>
             <div> {HowItWorksItem.image} </div>
                     </div>
            })   
      } 
         <div className="group-button-into">
                <Button onClick={onOk} className="button-into" type="primary">
                    {Constants.HIW_CTAButton2}
                </Button>
                <Button onClick={onHome} className="button-into" type="link">
                    {Constants.HIW_CTAButton3}
                </Button>
               
            </div>


    </div >
    )
}

export default Index;