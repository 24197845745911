import React from 'react';
import PropTypes from 'prop-types';
import * as Constants from '../../utils/constants';
import './LandingPageMenu.css';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { AutoCenter } from 'antd-mobile';
const { Header, Content, Footer } = Layout;


const LandingPageMenu = () => (
 <div className='w-menu-container'>
 <Header style={{
  position: 'sticky',
  top: 0,
  zIndex: 1,
  width: '1024px',
  backgroundColor: Constants.HeaderBackgroundColor,
  height:80,
  textAlign:'left',
  margin:'auto',
}}>
 <img src={Constants.LandingPageLogo} alt={Constants.LogoImageAlt} className='LogoImage' />
 </Header>
 </div>
);

LandingPageMenu.propTypes = {};

LandingPageMenu.defaultProps = {};

export default LandingPageMenu;
