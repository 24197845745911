import React, { useEffect, useState } from 'react'
import { Typography, Button, Form, Select, Input } from 'antd';
import { useHistory } from "react-router-dom";
import * as Constants from "../../utils/constants"
import './Login.scss';
import firebase from "../../services/firebase";
import { useDispatch, useSelector } from 'react-redux';
import {
    setUserState
} from "../../store/actions";
const { Option } = Select;
const { Title } = Typography;

const Index = () => {
    const [phone, setPhone] = useState()
    const [otp, setOtp] = useState()
    const [loading, setLoading] = useState(false)
    const [isCode, setIsCode] = useState(false)
    const prefix = "+1"
    const user = useSelector((state) => state.common.userState);
    const history = new useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (user && Object.keys(user).length > 0) {
            history.push("/send");
        }
    }, [user]);

    const cancelButton = () => {
        history.push('/');
    }
    const setUpRecaptcha = () => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
                size: "invisible",
                callback: function (response) {
                    console.log("Captcha Resolved");
                    onSignInSubmit();
                },
                defaultCountry: "US",
            }
        );
    };

    const onSignInSubmit = () => {
        setLoading(true);
        setUpRecaptcha();
        let phoneNumber = `${prefix}${Number(phone)}`;
        let appVerifier = window.recaptchaVerifier;
        firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(function (confirmationResult) {
                window.confirmationResult = confirmationResult;
                setIsCode(true);
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
            });
    };


    const onSubmitOtp = async (e) => {
        setLoading(true);
        let otpInput = otp;
        let optConfirm = window.confirmationResult;
        optConfirm
            .confirm(otpInput)
            .then(async (result) => {
                let user = result.user;
                if (user && !user.displayName) {
                    await firebase.database().ref('user').push({ name: "", phone: user.phoneNumber, message_remained: 6, timeCreate: firebase.database.ServerValue.TIMESTAMP, }).then(async (r) => {
                        if (r) {
                            user.updateProfile({
                                displayName: r.key,
                            })
                            await dispatch(setUserState(user));
                        }
                    })
                    //await firebase.database().ref('sub').push({ phone: user.phoneNumber, timeCreate: firebase.database.ServerValue.TIMESTAMP, })
                } else {
                    await dispatch(setUserState(user));
                }
                setLoading(false);
            })
            .catch(function (error) {
                alert("Incorrect OTP");
                setLoading(false);
            });
    };

    if (!isCode && Object.keys(user).length <= 0) {
        return (
            <div className="container-into">
                <div className="LoginTagline">{Constants.Login_Header}</div>
                <div className="LoginSubtitle">{Constants.Login_Subtitle}</div>
                <Form layout="vertical">
                    <Form.Item colon={false}>
                        <Input value={phone} placeholder="Phone Number" onChange={(value) => { setPhone(value.target.value) }} style={{ width: '100%', borderBottom: "1px solid rgb(212, 212, 212)", }} bordered={false} />
                    </Form.Item>
                </Form>
                <div id="recaptcha-container"></div>
                <div className="group-button-into">
                    <Button loading={loading} onClick={onSignInSubmit} className="LoginButton" type="primary">Next</Button>
                    <Button type="link" onClick={cancelButton}> Cancel </Button>
                </div>
            </div>
        )
    }

    if (isCode && Object.keys(user).length <= 0) {
        return (
            <div className="container-into">
            <div className="LoginTagline">{Constants.Login_Header}</div>
            <div className="LoginSubtitle">
            {Constants.Login_Subtitle2}
            <div className='LoginSubtitle'>{phone.toString()}</div>
            </div>
            
                <Form.Item colon={false} >
                    <Input value={otp} placeholder="Code" onChange={(value) => { setOtp(value.target.value) }} style={{ width: '100%', borderBottom: "1px solid rgb(212, 212, 212)", }} bordered={false} />
                </Form.Item>
                <div id="recaptcha-container"></div>
                <div className="group-button-into">
                    <Button loading={loading} onClick={onSubmitOtp} className="LoginButton" type="primary">Next</Button>
                    <Button type="link" onClick={cancelButton}> Cancel </Button>
                </div>
            </div>
        )
    }

    return (
        <div className="container-into">
            Loading...
        </div>
    )


}

export default Index;