import React from 'react'
import { Typography, Button } from 'antd';
import { useHistory, useLocation } from "react-router-dom";
import logo from '../../img/check.png';
import "./Success.scss";
import moment from "moment";

const { Title } = Typography;

const Index = () => {
    const history = new useHistory();
    const location = useLocation();
    const onDone = () => {
        history.push("./send");
    }
    return (
        <div className="container-into">
            <Title style={{ color: '#007aff' }}>Message Sent Successfully!</Title>
        
            <img src={logo} width="200" height="150" />
            <Title level={4} style={{ color: '#007aff', fontWeight: "500" }}>Sent time:</Title>
            <p>{location.state.timeCreate ? moment(location.state.timeCreate).format("MM/DD/YYYY, h:mm:ss a") : ""}</p>
            <Title level={4} style={{ color: '#007aff', fontWeight: "500" }}>To</Title>

            <p>{location.state.currentTag}</p>
           
            {/* <p>3500 Subscribers to the # comics hashtag</p> */}
            <Title level={4} style={{ color: '#007aff', fontWeight: "500" }}>Message:</Title>
            <p>{location.state.message ? location.state.message : ""}</p>
            <div className="group-button-into">
                <Button onClick={onDone} className="button-into" type="primary">Done</Button>
            </div>
        </div>
    )
}

export default Index;