import './App.css';
import AppRouters from "./routes";
import {
  setUserState
} from "./store/actions";
import firebase from "./services/firebase";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

function App() {

  const dispatch = useDispatch();

  function onAuthStateChanged(user) {
    if (user && Object.keys(user).length > 0) {
      dispatch(setUserState(user));
    }
  }

  useEffect(() => {
    const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber;
  }, []);

  return (
    <div className="App">
      <AppRouters />
    </div>
  );
}

export default App;
