import React, { useState } from 'react'
import { Icon, Menu, Dropdown, Image } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import firebase from "../services/firebase";
import { useDispatch } from 'react-redux';
import * as Constants from '../utils/constants';
import {
    setUserState
} from "../store/actions";
const HeaderGuest = () => {
    const brand = Constants.BrandName;
    const [active, setActive] = useState();
    const history = new useHistory();
    const dispatch = useDispatch();
    const clickMenu = (option) => {
        setActive(option)
        if (option === 1) {
            history.push("./login");
        }
        if (option === 2) {
            history.push("./howWork");
        }
    }

    const menu = (
        <Menu>
            <Menu.Item style={{ fontWeight: "500", backgroundColor: active == 1 ? '#f2f2f2' : "" }} onClick={() => { clickMenu(2) }}>
            {Constants.Menu_howitworks}
            </Menu.Item>
            <Menu.Item style={{ fontWeight: "500", backgroundColor: active == 4 ? '#f2f2f2' : "" }} onClick={() => { clickMenu(1) }}>
            {Constants.Menu_loginsignup}
            </Menu.Item>
        </Menu >
    );

    return (
        <div style={{
            height: 40, display: "flex", flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 10
        }}>
            <div style={{ width: "15%", justifyContent: 'flex-start', alignItems: 'center', display: 'flex' }}>
                <Dropdown overlay={menu} placement="bottomLeft">
                    <MenuOutlined style={{ fontSize: '25px', color: '#6e6e6e' }} />
                </Dropdown>
            </div>
            <div style={{ width: "65%", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                // TODO: TO see logo in header <img src="/logo_tmi.png" style={{ marginLeft: '20px', width: '100px' }} />
            </div>
            <div style={{ width: "15%" }}>

            </div>

        </div>
    )
}

export default HeaderGuest;