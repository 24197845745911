import {React} from 'react';
import './MessagePreview.css';
import dom from '../../img/dom.png' 
import * as Constants from '../../utils/constants';
import { Space,Image } from 'antd';



function MessagePreview(props){

    return(
        <div className='preview-holder'>
          <div className='preview-merchant-name'>
                    {Constants.PREVIEW_MERCHANTEMPLOYEE}
                </div>
            <div  >
                 <img src={dom} alt="preview"/>
            </div>
            <div className='preview-bubble-blue'>
            <div style={{paddingBottom:18}}>
                 {Constants.PREVIEW_MERCHANTPREPEND}
            </div>
        {props.message}
            <div style={{paddingTop:18}}>
                 {Constants.PREVIEW_REDEEM_LINK}
            </div>
        <Space direction="vertical" size="large"/>
        <div style={{paddingTop:18}}>
        {Constants.PREVIEW_STOP_MESSAGE}
        </div>
            </div>
                <div className='preview-person-name'>
                    {Constants.PREVIEW_NAME}
                </div>

            <div className='preview-bubble-grey'>
             {Constants.PREVIEW_CUSTOMER_RESPONSE}
            </div>

        </div>
    )

}

export default MessagePreview