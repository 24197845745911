import React, { useEffect, useState } from 'react';
import MessageForm from '../../components/MessageForm/MessageForm';

const Index= ()=>{
    return(

        <>
 
 <MessageForm/>
        
        </>
    )
}
export default Index