import React, { useEffect } from 'react'
import { Typography, Button } from 'antd';
import { ResultPage, Card,Space} from 'antd-mobile';
import * as Constants from '../../utils/constants';

import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import moment from "moment";
import "./Done.css";
const { Title } = Typography;


const Index = () => {
    const history = new useHistory();
    const location = useLocation();
    const user = useSelector((state) => state.common.userState);
    
    const onDone = () => {
        history.push("/send");
    };
    const HashtagVerbiage = Constants.ReplaceString(Constants.Done_Instructions,location?.state?.hashtag,"<<hashtag>>")
   


    const onCopy=()=>{
 
   
            console.log("hi");
            let div = document.getElementById('hashtaginfo');
            let text = div.innerText;
            let textArea  = document.createElement('textarea');
            textArea.width  = "1px"; 
            textArea.height = "1px";
            textArea.background =  "transparents" ;
            textArea.value = text;
            document.body.append(textArea);
            textArea.select();
            document.execCommand('copy');   //No i18n
            document.body.removeChild(textArea);
          

   };

    return (

<div className='SuccessDiv'>
   <Space>
        <div className='Title'>
            {Constants.Done_Title}
        </div>
    </Space>
    <div className='Subtitle'>
        {Constants.Done_Subtitle}
    </div>
    <hr></hr>

        <div id='hashtaginfo' className='HashtagDetails'>
            {HashtagVerbiage}
        </div>
        <div className="group-button-into">
    <Button 
    onClick={onCopy} className="button-into"  type="primary">
        {Constants.Done_CopyButton}
    </Button>
    <Button onClick={onDone} className="button-into" type="link">Done</Button>
    
</div>
<hr></hr>
    <div>
        
       <div className='SubtitleBold'> 
        {Constants.Done_DetailsHeader}
       </div> 
       <div className='TitleBold'>Hashtag</div>
       <div className='NormalText'>
       {location?.state?.hashtag ? location.state.hashtag.toUpperCase() : ""}
       </div>
       <div className='TitleBold'>Date created</div>
       <div className='NormalText'>
       {location?.state?.timeCreate ? moment(location.state.timeCreate).format("MM/DD/YYYY, h:mm:ss a") : ""}
       </div>

       
    </div>


</div>
    
    )
}

export default Index;