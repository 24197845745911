import React, { useEffect, useState } from 'react'
import { Typography, Button, Form, Input, Switch, List, Card, Radio, DatePicker, Row, Col, Select } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from "react-router-dom";
import firebase from "../../services/firebase";
import "./style.css";
import { useSelector } from 'react-redux';
import coverTime from "../../utils/CoverTime";
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { scheduleMessage, sendSMSInstant } from '../../store/actions';

const { Title } = Typography;
const { TextArea } = Input;

const ScheduleMessage = () => {

    const history = new useHistory();

    const dispatch = useDispatch();

    const [message, setMessage] = useState();

    const [isShow, setIsShow] = useState(false);

    const [loading, setLoading] = useState(false);

    const [radioValue, setRadioValue] = useState([]);

    const [data, setData] = useState([]);

    const [dataSub, setDataSub] = useState([]);

    const user = useSelector((state) => state.common.userState);

    const [hashtag, setHashtag] = useState("")

    const [isSchedule, setIsSchedule] = useState(false)

    const [date, setDate] = useState(new Date());

    const [selectedDateErr, setSelectedDateErr] = useState(false)

    const { Option } = Select;


    useEffect(async () => {
        if (user && Object.keys(user).length > 0) {
            setDataApi(user)
            setSubApi(user)
        }


        let nameHash = await firebase.database().ref('HashTags')
        console.log(nameHash)

    }, [user])

    const setSubApi = async (userCheck) => {
        setLoading(true);
        //  get all sub
        const snapsub = await firebase.database().ref('sub')
        snapsub.on('value', (valueSub, key) => {
            let sub = valueSub.val();
            // get all users_subs_hashtags
            const users_subs = firebase.database().ref('users_subs_hashtags')
            users_subs.on('value', async (valueAll, key) => {
                let valueCheckAll = valueAll.val();
                if (valueCheckAll) {
                    let arraySubAll = [];
                    if (userCheck && userCheck.phoneNumber) {
                        // get all users_hashtags
                        let dbRef = firebase.database().ref('users_hashtags')
                        dbRef.on('value', (val, key) => {
                            let value = val.val();
                            if (value && value[user.displayName]) {
                                Object.keys(value[user.displayName]).map(keyUserHash => {
                                    if (value[user.displayName][keyUserHash] == true && valueCheckAll[keyUserHash]) {
                                        Object.keys(valueCheckAll[keyUserHash]).map(keyUserSubHash => {
                                            if (sub[keyUserSubHash]) {
                                                arraySubAll = [...arraySubAll, sub[keyUserSubHash]]
                                                setDataSub(arraySubAll);
                                                setLoading(false)
                                            }
                                        })
                                    }
                                })
                            }
                        }, function (error) {
                            alert(error);
                            setLoading(false)

                        });
                    }
                }
            })
        })
    }



    const setDataApi = async (userCheck) => {
        setLoading(true);
        let HashTagsCheck = {};
        let nameHash = await firebase.database().ref('HashTags')
        nameHash.on('value', function (vale, key) {
            let value = vale.val();
            HashTagsCheck = value;
        })
        let array = [];
        if (userCheck && userCheck.phoneNumber) {
            let dbRef = firebase.database().ref('users_hashtags')
            dbRef.on('value', function (val, key) {
                let value = val.val();
                // user.displayName is key user
                // HashTagsCheck is all hashTAg table
                // keyCheck is key in users_hashtags
                if (value && value[user.displayName]) {
                    Object.keys(value[user.displayName]).map(keyCheck => {
                        if (value[user.displayName][keyCheck] == true && HashTagsCheck[keyCheck]) {
                            array = [...array, HashTagsCheck[keyCheck]]
                            setData(array)
                        }
                    })
                }
            }, function (error) {
                alert(error)
            });
        }
        setLoading(false)
    }


    const onFinish = async (values: any) => {
        console.log('Success:', values);
        setLoading(true)
        let data = {
            message: values.message,
            hashtag: hashtag,
            passcode: "tmi12345",
        }

        try {
            if (!isSchedule) {
                data.scheduledTime = moment.utc(date).format("YYYY-MM-DD[T]HH:mm:ss[Z]")
                console.log(data)
                let res = await dispatch(scheduleMessage(data));
                console.log(res)
                setLoading(false)
                setSelectedDateErr(false)
            }
            else {
                let res = await dispatch(sendSMSInstant(data));
                console.log(res)
                setLoading(false)
                setSelectedDateErr(false)
            }
        }
        catch (err) {
            setLoading(false)

        }

        setLoading(false)
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleChange = (value: string[]) => {
        console.log(`selected ${value}`);
        setHashtag(value)
    };

    const range = (start, end) => {
        const result = [];

        for (let i = start; i < end; i++) {
            result.push(i);
        }

        return result;
    };

    const onChange = (checked: boolean) => {
        console.log(`switch to ${checked}`);
        if (checked) {
            setIsSchedule(true)
        }
        else {
            setIsSchedule(false)
        }
    };


    const onChangeDate = (value) => {
        let selectedTime = moment(value).format("DD/MM/YYYY HH:mm")

        let timeAfter15Minutes = moment(moment(new Date).add(1000, 'seconds')).format("DD/MM/YYYY HH:mm")

        if (selectedTime > timeAfter15Minutes) {
            setDate((new Date(value)))
            console.log(false)
            setSelectedDateErr(false)
        }
        else {
            console.log(true)
            setDate(timeAfter15Minutes)
            setSelectedDateErr(true)
            alert('You can not schedule a message under 15 minutes.')
        }
    }

    return (
        <div className="container-into">

            <Row>
                <Col md={6}>

                </Col>
                <Col md={12} sm={24}>
                    <Card className='custom-card align-custom' >
                        <Title level={2} style={{ color: '#007aff', textAlign: 'center', marginTop: 0 }}>Schedule a message.</Title>
                        <Title level={4} style={{ color: '#424242', textAlign: 'center' }}>Use the form below to send a message to your followers.</Title>

                        <Form
                            name="basic"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >


                            <Form.Item
                                label="Select Hashtag"
                                name="hashtag"
                            // rules={[{ required: true, message: 'Please input hashtag!' }]}
                            >
                                <Select
                                    // mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="select one Tag"
                                    onChange={handleChange}
                                    optionLabelProp="label"
                                >
                                    {data.map((data, i) => (
                                        <Option key={i} value={data.value} label={data.value}>
                                            <div className="demo-option-label-item">
                                                {data.value}
                                            </div>
                                        </Option>
                                    ))
                                    }

                                </Select>
                            </Form.Item>
                            {!isSchedule ?
                                <Form.Item
                                    label="Date and time"
                                    name="date"
                                    rules={[{ required: true, message: 'Please input date and time!' }]}
                                >
                                    <DatePicker
                                        size='small'
                                        className='custom-input'
                                        format="YYYY-MM-DD HH:mm:ss"
                                        use12Hours={true}
                                        showTime={{
                                            defaultValue: moment('01:01:00', 'HH:mm:ss'),
                                        }}
                                        style={{ width: '100%' }}
                                        value={date}
                                        onChange={(value) => onChangeDate(value)}
                                    />
                                </Form.Item>
                                : null}

                            <Row style={{ display: selectedDateErr ? 'flex' : 'none' , marginTop:0}} >
                                <p style={{color:'red'}}> Please schedule a message atleast 15 minutes from now.</p>
                            </Row>


                            <Form.Item
                                label="Message"
                                name="message"
                                rules={[{ required: true, message: 'Please input your message!' }]}
                            >
                                <TextArea rows={4} className='custom-input' />
                            </Form.Item>

                            <Title level={4} style={{ color: '#424242', textAlign: 'left', marginBottom: 60 }}>Send instant Message? <Switch onChange={onChange} /></Title>

                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Button loading={loading} disabled={selectedDateErr} type="primary" htmlType="submit" className='custom-button' >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col md={6}>

                </Col>
            </Row>

            {/* <Form.Item colon={false}>
                <div style={{ marginBottom: 10, borderBottom: "1px solid rgb(212, 212, 212)" }}>
                    <Button className="button-into" style={{ textAlign: 'left', fontWeight: 500 }} type="link">Select Hashtag</Button>
                </div>
                <div>
                    <p style={{ textAlign: 'right', textDecoration: "underline", fontWeight: 500 }}>{dataSub && dataSub.length > 0 ? `${dataSub.length} Subscribers` : "Subscribers"}</p>
                </div>
                <TextArea rows={6} placeholder="Message goes Here" style={{ width: '100%', borderBottom: "1px solid rgb(212, 212, 212)", }} bordered={false} />
                <p style={{ textAlign: "right" }}>{message ? message.length : 0}/160</p>
            </Form.Item>
            <div className="group-button-into">

                <Title level={4} style={{ color: '#424242' }}> Hashtag : </Title>

                <Button className="button-into" type="primary">Send</Button>
            </div> */}

        </div>
    )
}

export default ScheduleMessage;