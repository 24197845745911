import React, { useState } from 'react'
import { Icon, Menu, Dropdown, Image } from 'antd';
import * as Constants from '../utils/constants';
import { MenuOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import firebase from "../services/firebase";
import { useDispatch } from 'react-redux';
import {
    setUserState
} from "../store/actions";
const Header = () => {
    const [active, setActive] = useState();
    const history = new useHistory();
    const dispatch = useDispatch();
    const clickMenu = (option) => {
        setActive(option)
        if (option === 1) {
            history.push("./howWork");
        }
        if (option === 2) {
            history.push("./send");
        }
        if (option === 4) {
            history.push("./hashtag");
        }
        if (option === 5) {
            history.push("./subscriber");
        }
        if (option === 6) {
            history.push("./schedule-message");
        }
        if (option === 3) {
            firebase.auth().signOut().then(() => {
                firebase.auth().signOut();
                dispatch(setUserState({}));
                history.push("./");
            })
                .catch((error) => {
                    alert(error);
                });
        }
    }

    const menu = (
        <Menu>
            <Menu.Item style={{ fontWeight: "500", backgroundColor: active === 1 ? '#f2f2f2' : "" }} onClick={() => { clickMenu(1) }}>
              {Constants.Menu_howitworks}
            </Menu.Item>
            <Menu.Item style={{ fontWeight: "500", backgroundColor: active === 4 ? '#f2f2f2' : "" }} onClick={() => { clickMenu(4) }}>
            {Constants.Menu_createhashtag}
            </Menu.Item>
            <Menu.Item style={{ fontWeight: "500", backgroundColor: active === 2 ? '#f2f2f2' : "" }} onClick={() => { clickMenu(2) }}>
            {Constants.Menu_sendamessage}
            </Menu.Item>
            <Menu.Item style={{ fontWeight: "500", backgroundColor: active === 2 ? '#f2f2f2' : "" }} onClick={() => { clickMenu(6) }}>
            {Constants.Menu_scheduleamessage}
            </Menu.Item>
            <Menu.Item style={{ fontWeight: "500", backgroundColor: active === 5 ? '#f2f2f2' : "" }} onClick={() => { clickMenu(5) }}>
            {Constants.Menu_statistics}
            </Menu.Item>
            <Menu.Item style={{ fontWeight: "500", backgroundColor: active === 3 ? '#f2f2f2' : "" }} onClick={() => { clickMenu(3) }}>
            {Constants.Menu_signout}
            </Menu.Item>
        </Menu >
    );

    return (
        <div style={{
            height: 40, display: "flex", flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 10
        }}>
            <div style={{ width: "15%", justifyContent: 'flex-start', alignItems: 'center', display: 'flex' }}>
                <Dropdown overlay={menu} placement="bottomLeft">
                    <MenuOutlined style={{ fontSize: '25px', color: '#6e6e6e' }} />
                </Dropdown>
            </div>
            <div style={{ width: "65%", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <img src={Constants.LogoImageBlack} style={{ marginLeft: '20px', width: '150px',padding:'1rem' }} />
            </div>
            <div style={{ width: "15%" }}>

            </div>

        </div>
    )
}

export default Header;