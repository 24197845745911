import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAk-j2D5ib6pd9-kRBZ_l0vu16icqpS2zc",
  authDomain: "tapmein-3b357.firebaseapp.com",
  databaseURL: "https://tapmein-3b357-default-rtdb.firebaseio.com",
  projectId: "tapmein-3b357",
  storageBucket: "tapmein-3b357.appspot.com",
  messagingSenderId: "276355125003",
  appId: "1:276355125003:web:0b72e263f19946293a9c0e"
};
firebase.initializeApp(firebaseConfig);
//const database = firebase.database();
//const storage = firebase.storage();
// export { storage, database, firebase as default };
export default firebase;
