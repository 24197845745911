import React from 'react';
import './HeadlineWithSubtitle.css';

const HeadlineWithSubtitle = (props) => (
  <div className="HeadlineWithSubtitle">
    <div className='Headline'>{props.Headline}</div>
    <div className='Subtitle'>{props.Subtitle}</div>
  </div>
);


export default HeadlineWithSubtitle;
