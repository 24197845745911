import React from 'react'
import { Typography, Button, Form, Input } from 'antd';
import { useHistory } from "react-router-dom";
import * as Constants from '../../utils/constants';

const { Title } = Typography;

const Index = () => {
    const history = new useHistory();
    const onNext = () => {
        history.push("/send");
    }
    return (
        <div className="container-into">
            <Title style={{ color: '#007aff' }}>{Constants.CODE_Title}</Title>
            <Form.Item colon={false} label={Constants.CODE_Subtitle}>
                <Input placeholder="Code" style={{ width: '70%' }} />
            </Form.Item>
            <div className="group-button-into">
                <Button onClick={onNext} className="button-into" type="primary">Next</Button>
            </div>
        </div>
    )
}

export default Index;