import React,{ useEffect, useState }  from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import './LandingPageContent.css';
import {  Button,Layout } from 'antd';
import { LandingContentBackgroundColor } from '../../utils/constants';
import * as Constants from '../../utils/constants';
import HeadlineWithSubtitle from '../HeadlineWithSubtitle/HeadlineWithSubtitle';
const { Content } = Layout;

const LandingPageContent = () => { 

  const history = new useHistory();
  const user = useSelector((state) => state.common.userState);
  
  const onSignUp = () => {
      history.push("/signup");
  }
  const onLogin = () => {
      history.push("/login");
  }
  
  const onHowItWork = () => {
      history.push('/howWork');
  }
  
  useEffect(() => {
      if (user && Object.keys(user).length > 0) {
          history.push("/send");
      }
  }, [user]);
  return (
 
  <Content
        className="site-layout"
        style={{
          padding: '1rem',
          backgroundColor:Constants.LandingContentBackgroundColor,
        }}
      >
    <div className="container-into-main">
  
      <div className='HeroContentDiv'>
          <div className="lp-tagline"  >{Constants.OpeningTagline}</div>
          <div className="lp-subtitle"  >{Constants.OpeningSubtitle}</div>
          <div className="group-button-into">
                <Button className="button-into" 
                type="primary" 
                onClick={onSignUp}>
                {Constants.btnSignUp}
                </Button>

                <div className='secondaryButtons'>
                <div>
                <button onClick={onLogin}
                 className="OutlineButton" 
                 >
                 {Constants.btnLogin}
                 </button>
                 </div>
                 
                <div>
                <button className="YellowSolidButton" 
                onClick={onHowItWork}>
                {Constants.btnHowItWorks}
                </button>
                </div>

                </div>
                

            </div>
        </div>
    <div className='HeroImageDiv'>
    <div>
    <img src={Constants.LandingPageHeroImage} alt={Constants.LogoImageAlt} className="lp-hero-image" />
    </div>
    </div>
       
        
           
    </div>
  </Content>
)};

LandingPageContent.propTypes = {};

LandingPageContent.defaultProps = {};

export default LandingPageContent;
