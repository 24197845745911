import React, { useEffect, useState } from 'react'
import { Typography, Empty, Skeleton, List, Button } from 'antd';
import { useHistory } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';
import firebase from "../../services/firebase";
import { useSelector } from 'react-redux';
import coverTime from "../../utils/CoverTime";

const { Title } = Typography;

const Index = () => {
    const history = new useHistory();
    const [loading, setLoading] = useState(false);
    const [dataSub, setDataSub] = useState([]);
    const user = useSelector((state) => state.common.userState);

    const setSubApi = async (userCheck) => {
        setLoading(true);
        //  get all sub
        const snapsub = await firebase.database().ref('sub')
        snapsub.on('value', (valueSub, key) => {
            let sub = valueSub.val();
            // get all users_subs_hashtags
            const users_subs = firebase.database().ref('users_subs_hashtags')
            users_subs.on('value', async (valueAll, key) => {
                let valueCheckAll = valueAll.val();
                if (valueCheckAll) {
                    let arraySubAll = [];
                    if (userCheck && userCheck.phoneNumber) {
                        // get all users_hashtags
                        let dbRef = firebase.database().ref('users_hashtags')
                        dbRef.on('value', (val, key) => {
                            let value = val.val();
                            if (value && value[user.displayName]) {
                                console.log('Here I', value, value[user.displayName]);
                                Object.keys(value[user.displayName]).map(keyUserHash => {
                                    if (value[user.displayName][keyUserHash] == true && valueCheckAll[keyUserHash]) {
                                        Object.keys(valueCheckAll[keyUserHash]).map(keyUserSubHash => {
                                            if (sub[keyUserSubHash]) {
                                                arraySubAll = [...arraySubAll, sub[keyUserSubHash]]
                                                console.log({ arraySubAll })
                                                setDataSub(arraySubAll);
                                                setLoading(false)
                                            }
                                        })
                                    }
                                })
                            }
                        }, function (error) {
                            alert(error);
                            setLoading(false)

                        });
                    }
                }
            })
        })
    }





    const unSub = async (userCheck) => {
        setLoading(true);
        //  get all sub
        const snapsub = await firebase.database().ref('sub')
        snapsub.on('value', (valueSub, key) => {
            let sub = valueSub.val();
            // get all users_subs_hashtags
            const users_subs = firebase.database().ref('users_subs_hashtags')
            users_subs.on('value', async (valueAll, key) => {
                let valueCheckAll = valueAll.val();
                if (valueCheckAll) {
                    let arraySubAll = [];
                    if (userCheck && userCheck.phoneNumber) {
                        // get all users_hashtags
                        let dbRef = firebase.database().ref('users_hashtags')
                        dbRef.on('value', (val, key) => {
                            let value = val.val();
                            if (value && value[user.displayName]) {
                                console.log('Here I', value, value[user.displayName]);
                                Object.keys(value[user.displayName]).map(keyUserHash => {
                                    if (value[user.displayName][keyUserHash] == true && valueCheckAll[keyUserHash]) {
                                        Object.keys(valueCheckAll[keyUserHash]).map(keyUserSubHash => {
                                            if (sub[keyUserSubHash]) {
                                                arraySubAll = [...arraySubAll, sub[keyUserSubHash]]
                                                console.log({ arraySubAll })
                                                setDataSub(arraySubAll);
                                                setLoading(false)
                                            }
                                        })
                                    }
                                })
                            }
                        }, function (error) {
                            alert(error);
                            setLoading(false)

                        });
                    }
                }
            })
        })
    }




    const onSend = () => {
        history.push("/send");
    }

    useEffect(() => {
        if (user && Object.keys(user).length > 0) {
            setSubApi(user)
            unSub(user)
        }
    }, [user])

    return (
        <div className="container-into">
            <Title level={2} style={{ color: '#007aff', textAlign: 'left', marginTop: 20 }}>Stats.</Title>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                <div style={{ width: '50%' }}>
                    <Title level={1} style={{ color: '#007aff', marginBottom: 0 }}>{dataSub && dataSub.length}</Title>
                    <p style={{ color: "#6e6e6e" }}>Subscribers</p>
                </div>
                <div style={{ width: '50%' }}>
                    <Title level={1} style={{ color: '#007aff', marginBottom: 0 }}>6</Title>
                    <p style={{ color: "#6e6e6e", marginBottom: 0 }}>Messages left this month</p>
                </div>
            </div>
            <div style={{ display: "flex", width: 'calc(40px + 100%)', marginLeft: -20 }}>
                {
                    dataSub && dataSub.length > 0 ? <div style={{ display: 'flex', width: 'calc(100%)', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%' }} className="infinite-container">
                            <InfiniteScroll>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={dataSub}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20 }}
                                        >
                                            <div style={{ width: '100%', display: 'flex', justifyContent: "flex-start", alignItems: 'flex-start', fontSize: 20, fontWeight: '500' }}>
                                                {`xxxx-xxxx-${item.phone.substring(8)}`}

                                            </div>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: "flex-start", alignItems: 'flex-start', fontSize: 16, color: "#9e9e9e" }}>
                                                {item.timeCreate ? `Subscribed ${coverTime(item.timeCreate)}` : ""}
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </InfiniteScroll>
                        </div>

                    </div> : <div style={{ display: 'flex', width: 'calc(100%)', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                }

            </div>
            <div className="group-button-into">
                <Button onClick={onSend} className="button-into" type="primary">Send a new message</Button>
            </div>
        </div >
    )
}

export default Index;