
export const ReplaceString = (InitalString,ReplacementString,StringToReplace) => {
    let finalstring = InitalString.replace(StringToReplace, ReplacementString)
    return finalstring
};


export class HowItWorksListItem{
    constructor(steptitle,subtitle,image){
    this.steptitle = steptitle;
    this.subtitle = subtitle;
    this.image = image;
    }
};




//Main Intro Site Copy/Text
export const BrandName = "CafeText";
export const OpeningTagline ="Help your customers love you more."
//export const OpeningTagline =" 👋 Let's stay connected.";
export const OpeningTagline_secondary =" 👋 Let's stay in touch.";
export const OpeningSubtitle = BrandName + " is a text message marketing platform that helps cafe’s and coffee shops retain customers."
export const OpeningSubtitle2 = BrandName + " is a text message marketing platform that helps businesses retain customers."
export const OpeningSubtitle3 = BrandName + " is a SMS marketing tool that reduces the time cafe owners spend on marketing efforts, while increasing foot traffic."

// Color / Images

//Colors
export let defaultColor = "#383838";
export let HeaderBackgroundColor = "#ffffff"
export let LandingContentBackgroundColor="#fbfaf0";


export let LogoImageWhite = "./logo-tribe-white.svg";
export let LogoImageBlack = "./logo-tribe-black.svg";
export let LogoImageAlt = "CafeText";
export let HeroImage = "./hero-image.png";
export let LandingPageLogo = "./logo-header.svg";
export let LandingPageHeroImage = "./textbubble.svg";



//Main

export let btnSignUp = "See how "+BrandName+" can work for you"
export let btnSignUp2 = "Try it today"
export let btnSignUp3 = "Try it now"
export let btnSignUp4 = "Try it for yourself."
export let btnLogin = "Login"
export let btnHowItWorks = "Learn more"

//Send
export const Send_Title=" 👋 Send your text message"
export const Send_Error="Please enter a message you would like to send.";
export const Send_Subtitle="Send a message to your customers, by filling out the info below.";
export const Send_Hashtag_placeholder = "Select your subscriber Hashtag";
export const Send_Hashtag_placeholder2 = "Click here to change subscriber Hashtag";
export const Send_Message_placeholder = "Craft the message you would like to send here."
export const Send_SelectHashtag = "Select your subscriber Hashtag";
export const Send_SendButton = "Send your message"

// Header and Header Guest Menu
export const Menu_howitworks="How " + BrandName +" Works";
export const Menu_createhashtag="Create a Hashtag";
export const Menu_sendamessage="Send A Message ";
export const Menu_scheduleamessage="Schedule a Message";
export const Menu_statistics="My Statistics";
export const Menu_signout="Sign Out";
export const Menu_loginsignup="Login/Sign Up";

//Hashtag
export const Hashtag_Title = "Create your #hashtag.";
export const Hashtag_Subtitle = "We use #hashtags to allow customers to subscribe to the text messages you send . Use your hashtags to send promotions,offers and deals to a subset of customers."
export const Hashtag_Input_placeholder = "#Hashtag"

// Done Screen

export const Done_Title = "Success!";
export const Done_Subtitle = "You've created your hashtag! Start using it by giving the following instructions below to your customers, to get them to subscribe."

export const Done_Instructions = "Text hashtag #<<hashtag>> to (415) 338-9998 to get exclusive promotions ,offers, and discounts from us!."

export const Done_DetailsHeader ="Details:";
export const Done_CopyButton ="Copy Message";
//Login 

export const SignUp_Header ="Welcome to " + BrandName + "";
export const SignUp_Subtitle = "Please enter your mobile number you would like to use to sign up. You will receive all security codes to login at this number"
export const Login_Header ="Login to " + BrandName;
export const Login_Subtitle = "Please enter your mobile number you used to sign up. You will receive a security code to login at this number"
export const Login_Subtitle2 = "Please enter the security code that was sent to : "
// How it works


export const HIW_Title ="How " + BrandName +" Works";
export const HIW_CTAButton ="Get Started Now";
export const HIW_CTAButton2 ="Sign up to get started today.";
export const HIW_CTAButton3 ="Go Back";


const HIW_Step1 ="1. Create your subscriber #hashtag";
const HIW_Step1_subtitle ="Setting up your subscriber hashtag allows your customers to start receiving marketing messages from you.";
const HIW_Step1_Image ="";

const HIW_Step2 ="2. Tell your customers.";
const HIW_Step2_subtitle ="Allow your customers and followers to subcribe to your text messages by placing the hashtag message on your website, social media profile, you can even display in your signage in your shop.";
const HIW_Step2_Image ="";

const HIW_Step3 ="3. Start sending messages . Today!";
 const HIW_Step3_subtitle =  BrandName + " gives you a wide selection of template messages to choose from to get the attention of your customers.";
const HIW_Step3_Image ="";

const HIWItem1 =  new HowItWorksListItem(HIW_Step1,HIW_Step1_subtitle,HIW_Step1_Image)
const HIWItem2 = new HowItWorksListItem(HIW_Step2,HIW_Step2_subtitle,HIW_Step2_Image)
const HIWItem3 = new HowItWorksListItem(HIW_Step3,HIW_Step3_subtitle,HIW_Step3_Image)

export const HowItWorksItems = [HIWItem1,HIWItem2,HIWItem3]


// Message Form

export const MF_Headline="Create a campaign"
export const MF_Subtitle = "Use the form below to create, schedule and send a text message to your customers."
export const MF_SegmentLabel = "Please select your segment"
export const MF_SegmentSelect = ["All Customers","Purchased > 90 days ago", "New Customers"]
export const MF_MessageLabel ="Create a custom message or select a message template from our library."
export const MF_LibraryButtonLabel ="Choose a pre-made text message from our library"
export const MF_MessageSample ="Example : Treat yourself to a discounted snack at our coffee shop on your way home! 🍰 or 🌮 (while supplies last!)"
export const MF_Frequency = ["Once","Daily","Weekly","Monthly"]
export const MF_FrequencyLabel = "How often should this message be sent?"

export const MF_DateTimeLabel ="Select a date and time to start sending on."

export const MF_EndDateTimeLabel = "How many days should this campaign run?"

export const MF_EndingValues = ["30 Days","60 Days","90 Days","6 Months"]

export const MF_Mock_Messages =  {
    "Commuter Messages":      
    [ "Happy hour is here! Stop by on your commute home for 50% off sandwiches or 25% off pastries.", 
      "Need a pick-me-up on your commute home? Stop by and get 25% off any drink with this code: COMMUTE25",  
      "Don't let the evening rush catch you off guard! Swing by on your commute for a free cookie with any drink purchase.",  
      "Treat yourself on your commute home! Get 50% off any slice of cake with this code: COMMUTE50", 
      "Feeling peckish on your way home? Stop by for a free small coffee with any sandwich purchase.",  
      "Fuel up for the rest of your day with 20% off any drink on your commute home. Just show this message at the counter.", 
      "Last chance for lunch! On your commute home, get a free bagel with any breakfast sandwich purchase.",  
      "Happy hump day! Swing by on your commute home for 25% off any drink.", 
      "Halfway through the week and feeling drained? Stop by on your commute home for a free pastry with any drink purchase.",  
      "End your day on a sweet note! Get 50% off any slice of pie on your commute home with this code: COMMUTE50",  
      "Good morning! Get your caffeine fix on your commute with 2 discount options: a free pastry with the purchase of any coffee or $1 off any drink. Offer valid until 10am.",  
      "Need a pick-me-up during your commute? Stop by our coffee shop and choose between 2 discounts: a free espresso shot with the purchase of any drink or $2 off a specialty coffee. Offer valid until noon.", 
      "Commuting can be tough, but we've got you covered with 2 discount options to brighten your day: a free bagel with the purchase of any coffee or $3 off any drink. Offer valid until 2pm.", 
      "Don't let your commute get you down! Treat yourself to a discount at our coffee shop: choose between a free pastry with the purchase of any coffee or $2 off any drink. Offer valid until 4pm.", 
      "Need a little boost during your commute? Swing by our coffee shop for 2 discount options: a free espresso shot with the purchase of any drink or $3 off a specialty coffee. Offer valid until 6pm.", 
      "Commute got you feeling drained? Refresh with 2 discount options at our coffee shop: a free bagel with the purchase of any coffee or $2 off any drink. Offer valid until 8pm.",  
      "Make your commute a little sweeter with 2 discount options at our coffee shop: a free pastry with the purchase of any coffee or $1 off any drink. Offer valid until 10pm.",  
      "Don't let your commute bring you down! Stop by our coffee shop for a pick-me-up and choose between 2 discounts: a free espresso shot with the purchase of any drink or $2 off a specialty coffee. Offer valid until midnight.",  
      "Commuting can be rough, but we've got your back with 2 discount options: a free bagel with the purchase of any coffee or $3 off any drink. Offer valid until 2am.",  
      "Need a little boost during your commute? Visit our coffee shop for 2 discount options: a free pastry with the purchase of any coffee or $2 off any drink. Offer valid until 4am."     
    ],
    "Gen Z Messages":      
[ "🚨 NEW DEAL ALERT 🚨 Show this text and get 20% off your next order at [Cafe Name]! 😍",  
  "💰 BOGO ALERT 💰 Buy one drink, get one free at [Cafe Name] with this text! 🤑",  
  "🎉 CELEBRATE 🎉 It's [Cafe Name]'s anniversary and we're offering 25% off all drinks with this text! 🎂", 
  "🌟 EXCLUSIVE OFFER 🌟 Show this text and get a FREE pastry with your next drink at [Cafe Name]! 🍪",  
  "🚀 LAUNCH DEAL 🚀 Get 30% off your first order at [Cafe Name] with this text! 🚀",  
  "🤑 EARLY BIRD 🤑 Get a FREE coffee when you visit [Cafe Name] before 10am with this text! 🌞",  
  "💰 REFERRAL REWARD 💰 Refer a friend to [Cafe Name] and get a FREE drink with this text! 🤝", 
  "🎁 GIFT 🎁 Show this text and get a FREE drink on us at [Cafe Name]! 🎁", 
  "💰 FLASH SALE 💰 Get 20% off your order at [Cafe Name] today only with this text! 💰",  
  "💰 LOYALTY REWARD 💰 Show this text and get a FREE drink after your 5th visit to [Cafe Name]! 💰",  
    
  "🚨🚨🚨 Flash Sale Alert 🚨🚨🚨 Get 20% off your next coffee order when you mention this text! 🤑",  
  "💰💰💰 Don't miss out on this amazing deal! 💰💰💰 Get 25% off your next order when you show this text at checkout! 🤑",  
  "💰💰💰 Need a caffeine fix? 💰💰💰 Get $1 off your next coffee order when you show this text at checkout! ☕️", 
  "🎉🎉🎉 It's our anniversary and we want to celebrate with you! 🎉🎉🎉 Get 25% off your next order when you mention this text! 🤑",  
  "🎉🎉🎉 Happy Hour Alert! 🎉🎉🎉 Get $1 off all drinks from 4-6pm when you show this text at checkout! ☕️", 
  "🚨🚨🚨 Limited Time Offer! 🚨🚨🚨 Get 30% off your next order when you mention this text! 🤑",  
  "💰💰💰 Want to save some cash on your next coffee fix? 💰💰💰 Show this text at checkout to get $1 off your order! ☕️",  
  "🎉🎉🎉 It's National Coffee Day! 🎉🎉🎉 Celebrate with us and get 25% off your next order when you mention this text! ☕️", 
  "💰💰💰 Don't miss out on this amazing deal! 💰💰💰 Get 30% off your next order when you show this text at checkout! 🤑",  
  "🚨🚨🚨 Flash Sale Alert! 🚨🚨🚨 Get 25% off your next coffee order when you mention this text! ☕️",  
    
  "🎉🎉🎉 Get ready to celebrate! 🎉🎉🎉 Use code COFFEEPARTY to get 20% off your next visit to our coffee shop. 🙌",  
  "🚨🚨🚨 FLASH SALE 🚨🚨🚨 Use code JAVAJOLT to get 25% off your next coffee order. ☕️ Hurry, offer ends soon! 🏃‍♂️",  
  "🌟🌟🌟 VIP TREATMENT 🌟🌟🌟 Use code COFFEELOVE to get a free upgrade to a large coffee on your next visit. ❤️", 
  "🎁🎁🎁 GIFT FOR YOU 🎁🎁🎁 Use code COFFEESURPRISE to get a free pastry with your next coffee order. 🍩", 
  "🤑🤑🤑 SAVINGS ALERT 🤑🤑🤑 Use code COFFEECASH to get $2 off your next coffee purchase. 💰", 
  "📣📣📣 ANNOUNCEMENT 📣📣📣 Use code NEWFLAVOR to get 50% off your first order of our new specialty coffee flavor. 🆕",  
  "🎯🎯🎯 HIT THE TARGET 🎯🎯🎯 Use code COFFEETARGET to get a free espresso shot with your next drink purchase. 🎯",  
  "🎂🎂🎂 BIRTHDAY BASH 🎂🎂🎂 Use code BDAYCOFFEE to get a free small coffee on your birthday. 🎂", 
  "🤩🤩🤩 EXCITING NEWS 🤩🤩🤩 Use code COFFEETRIP to get a free coffee travel mug with your next purchase of $20 or more. 🛍️", 
  "🍰🍰🍰 SWEET DEAL 🍰🍰🍰 Use code COFFEESWEET to get a free slice of cake with your next coffee order. 🎂", 
    
  "🎉🎉🎉 Hey there, coffee lover! 🎉🎉🎉 We've got a special deal just for you: 🤑 20% off your next purchase 🤑 or 🤑 a free pastry 🤑 with your order. Which one will it be?", 
  "🚨🚨🚨 ALERT: Exclusive offer for our favorite Gen Z customers! 🚨🚨🚨 Choose between 💰 20% off your next order 💰 or 💰 a free upgrade to a large drink 💰 on your next visit. Hurry, this offer won't last long!",  
  "🎁🎁🎁 It's your lucky day! 🎁🎁🎁 We're offering a choice of two awesome discounts: 💰 a free pastry 💰 with your next order, or 💰 25% off your total purchase 💰. Which one will you choose?",  
  "🤑🤑🤑 Feeling spendy? 🤑🤑🤑 We've got a deal for you: 💰 20% off your next purchase 💰 or 💰 a free drink upgrade 💰. Which one will it be?",  
  "🎉🎉🎉 Time for a treat! 🎉🎉🎉 Choose between 💰 a free pastry 💰 with your next order, or 💰 20% off your total purchase 💰. Don't wait, this offer won't last long!", 
  "🚨🚨🚨 Attention, coffee lovers! 🚨🚨🚨 We've got a special deal just for you: 💰 20% off your next order 💰 or 💰 a free drink upgrade 💰. Which one will you choose?", 
  "🎁🎁🎁 It's time to treat yo'self! 🎁🎁🎁 Choose between 💰 a free pastry 💰 with your next order, or 💰 25% off your total purchase 💰. Don't wait, this offer won't last long!", 
  "🤑🤑🤑 Feeling frugal? 🤑🤑🤑 We've got a deal for you: 💰 a free pastry 💰 with your next order, or 💰 20% off your total purchase 💰. Which one will it be?",  
  "🎉🎉🎉 Time for a caffeine fix! 🎉🎉🎉 Choose between 💰 a free drink upgrade 💰 with your next order, or 💰 25% off your total purchase 💰. Don't wait, this offer won't last long!", 
  "🚨🚨🚨 Attention, Gen Z coffee drinkers! 🚨🚨🚨 We've got a special deal just for you: 💰 a free pastry 💰 with your next order, or 💰 20% off your total purchase 💰. Which one will you choose?",  
    
  "💥 FLASH SALE 💥 Get 20% off all drinks today only! Just show this text at checkout.", 
  "☕️ NEW DRINK ALERT ☕️ Come try our pumpkin spice latte, complete with whipped cream and a dash of cinnamon. Bonus: buy one, get one 50% off!", 
  "🍰 TREAT YO' SELF 🍰 Come in today and get a free slice of cake with the purchase of any drink.",  
  "🎉 IT'S OUR BIRTHDAY 🎉 Come celebrate with us and get a free pastry with any drink purchase.",  
  "🌟 STUDENT DISCOUNT 🌟 Show us your student ID and get 10% off your order.", 
  "🌿 GO GREEN 🌿 Bring in your own reusable mug and get $1 off your drink.", 
  "🌤 SUMMER SAVINGS 🌤 Beat the heat with a cold brew and get $2 off.",  
  "🎤 OPEN MIC NIGHT 🎤 Come perform or just listen to some local talent and get 10% off any drink.", 
  "🍪 COOKIE MONSTER 🍪 Get a free cookie with any drink purchase today only.", 
  "💻 WORK FROM HERE 💻 Bring your laptop and get 50% off any drink when you purchase a pastry.", 
    
  "🚨🆕 NEW DRINK ALERT 🆕🚨 Try our latest creation, the <DRINK NAME HERE>, on your next visit and get a free biscotti with your purchase! 🥥☕️🍪",  
  "💰💰 2 FOR 1 DEAL 💰💰 Buy any drink and get a second one FREE for you or a friend! Just show this text at the counter. ☕️🎉",  
  "💜💜 PINK DRINKS FOR PRIDE 💜💜 Swing by today and show your support with a purchase of our limited-edition <DRINK NAME HERE> 🌈☕️🌈",  
  "🎂🎂 IT'S SOMEONE'S BIRTHDAY 🎂🎂 Show us your ID and celebrate with a FREE slice of cake with any drink purchase! 🎉🍰☕️",  
  "😴😴 MID-WEEK PICK-ME-UP 😴😴 Treat yourself to a free upgrade to a large coffee with any pastry purchase on Wednesday mornings. 🍩☕️💪",  
  "🍴🍴 BRUNCH BONANZA 🍴🍴 Get a FREE coffee with any brunch entree on weekends! Mimosas not included...but they are available for purchase. 🥂☕️🍳",  
  "📚📚 STUDY SESSION DEAL 📚📚 Get a discounted latte with the purchase of any sandwich or salad during weekday afternoons. Perfect for powering through that study session. 🍴☕️📝",  
  "🏋️‍♀️🏋️‍♀️ POST-WORKOUT FUEL 🏋️‍♀️🏋️‍♀️ Stop by in your gym clothes and get a free protein ball with any drink purchase. 💪🥢🏋️‍♀️", 
  "🍦🍦 BEAT THE HEAT 🍦🍦 Cool off with a free upgrade to a large iced coffee with any pastry purchase on hot summer days. ☀️🍦☕️", 
  "🍹🍹 HAPPY HOUR 🍹🍹 Swing by during our daily happy hour (4-6pm) and get a discounted latte or espresso cocktail. Cheers! 🍹🥂☕️",  
    
  "🎉🎉Celebrate the weekend with us! Buy any drink and get a free pastry with this code: WEEKEND🎉🎉", 
  "☕️Need a pick-me-up? Come in today and get 50% off any specialty drink with code: ENERGIZE☕️", 
  "💥Don't miss out! Come in this week and get a free cookie with any purchase using code: COOKIECRAZE💥",  
  "🍪🍪Get a free upgrade to a medium drink with code: UPGRADE🍪🍪",  
  "🌟Come in today and get a free pastry with code: TREATYOURSELF🌟", 
  "💰💰Save money on your next visit! Use code: BUDGETFRIENDLY for $1 off your purchase💰💰", 
  "🎁🎁Get a free small drink with code: GIFT🎁🎁", 
  "🎉🎉Celebrate National Coffee Day with us! Get a free small coffee with code: COFFEEDAY🎉🎉",  
  "💥💥Get a buy one, get one free deal on any drink with code: BOGO💥💥",  
  "☕️☕️Get a free medium drink with code: FREEDRINK☕️☕️", 
    
  "🎉 Flash sale alert! 🎉 Visit us today and get 50% off your order when you show this text. 💰", 
  "💰 Treat yourself to a free pastry with your coffee order when you mention this text. 🥐", 
  "🎁 Get a free drink on us when you bring a friend and mention this text. 🍹",  
  "💰 Score a $5 gift card when you make a purchase and show this text. 🎉",  
  "🎉 Happy hour alert! 🎉 Visit us between 4-6pm and get a free pastry with your drink order when you show this text. 🍹",  
  "💰 Get a free upgrade to a large coffee when you order a medium and show this text. ☕️",  
  "🎁 Score a free drink when you check in on social media and show this text. 📱", 
  "🎉 Weekend special! 🎉 Get a free pastry with your drink order when you visit on Saturday or Sunday and mention this text. 🍹", 
  "💰 Get a free drink when you make a purchase and show this text. Valid for a limited time only. 🎁", 
  "🎁 Get a free drink on your birthday when you show this text and a valid ID. 🎂"  
] }  



// Preview Modal
export const PREVIEW_NAME = "Sam"
export const PREVIEW_NAME_PREPEND = "Hi " + PREVIEW_NAME + ", "
export const PREVIEW_MERCHANTEMPLOYEE = "Dom"
export const PREVIEW_MERCHANT = "Luna Café"
export const PREVIEW_MERCHANTPREPEND = PREVIEW_NAME_PREPEND + " its " + PREVIEW_MERCHANTEMPLOYEE + " from " + PREVIEW_MERCHANT
export const PREVIEW_HEADER_TITLE = "Message Preview"
export const PREVIEW_HEADER_SUBTITLE = "Below is a preview of how your message may appear to your customers when sent."
export const PREVIEW_CUSTOMER_RESPONSE = "Wow! Thanks.I will be stopping by today."
export const PREVIEW_REDEEM_LINK = `

Tap here to redeem : ctxt.app/12345


Offer valid until 00:00pm, and while supplies last.

 `
export const PREVIEW_STOP_MESSAGE = ` Text STOP to opt out of these messages. `





// Code (confirmation code)
export const CODE_Title = "Sign up for " + BrandName;
export const CODE_Subtitle ="Please enter the code that was sent to your mobile number."

 

// Mock Customers

export const ALL_CUSTOMERS = {
  "customers": [
    {
      "id": "JDKYHBWT1D4F8MFH63DBMEN8Y4",
      "created_at": "2016-03-23T20:21:54.859Z",
      "updated_at": "2016-03-23T20:21:55Z",
      "given_name": "Amelia",
      "family_name": "Earhart",
      "email_address": "Amelia.Earhart@example.com",
      "address": {
        "address_line_1": "500 Electric Ave",
        "address_line_2": "Suite 600",
        "locality": "New York",
        "administrative_district_level_1": "NY",
        "postal_code": "10003",
        "country": "US"
      },
      "phone_number": "+1-212-555-4240",
      "reference_id": "YOUR_REFERENCE_ID",
      "note": "a customer",
      "preferences": {
        "email_unsubscribed": false
      },
      "creation_source": "THIRD_PARTY",
      "group_ids": [
        "545AXB44B4XXWMVQ4W8SBT3HHF"
      ],
      "segment_ids": [
        "1KB9JE5EGJXCW.REACHABLE"
      ],
      "version": 1
    },
    {
      "id": "LKJHGFR1D9F7MHJ62DBNEN5Y7",
      "created_at": "2015-05-11T12:51:34.659Z",
      "updated_at": "2015-05-11T12:51:35Z",
      "given_name": "Oliver",
      "family_name": "Smith",
      "email_address": "Oliver.Smith@example.com",
      "address": {
        "address_line_1": "700 Main St",
        "address_line_2": "Apt 300",
        "locality": "Los Angeles",
        "administrative_district_level_1": "CA",
        "postal_code": "90210",
        "country": "US"
      },
      "phone_number": "+1-310-555-1212",
      "reference_id": "OLIVER_REFERENCE_ID",
      "note": "a customer",
      "preferences": {
        "email_unsubscribed": false
      },
      "creation_source": "INTERNAL",
      "group_ids": [
        "645AXB44B4XXWMVQ4W8SBT3HHF"
      ],
      "segment_ids": [
        "2KB9JE5EGJXCW.UNREACHABLE"
      ],
      "version": 2
    },
    {
      "id": "MNOPQRST2D8F6LGI61DBNEN4Y6",
      "created_at": "2014-02-22T08:31:24.659Z",
      "updated_at": "2014-02-22T08:31:25Z",
      "given_name": "Emma",
      "family_name": "Johnson",
      "email_address": "Emma.Johnson@example.com",
      "address": {
        "address_line_1": "900 Broadway",
        "address_line_2": "",
        "locality": "New York",
        "administrative_district_level_1": "NY",
        "postal_code": "10010",
        "country": "US"
      },
      "phone_number": "+1-212-555-5678",
      "reference_id": "EMMA_REFERENCE_ID",
      "note": "a customer",
      "preferences": {
        "email_unsubscribed": true
      },
      "creation_source": "MANUAL",
      "group_ids": [
        "745AXB44B4XXWMVQ4W8SBT3HHF"
      ],
      "segment_ids": [
        "3KB9JE5EGJXCW.OPT-IN"
      ],
      "version": 3
    } 
  ]
}




//Buttons + Labels



// Link Generator

export const ShortURLStart = "https://cafetext.co/"
